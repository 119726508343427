<script>
// wow.js
const WOW = require('wow.js');
import 'animate.css';

// scroll-hint
import ScrollHint from 'scroll-hint';
import 'scroll-hint/css/scroll-hint.css';

// vue components
import gotop from './components/Gotop.vue';
import aco from './components/Aco.vue';
import btnsubmit from './components/BtnSubmit.vue';
import formdeleteinvalid from './components/FormDeleteInvalid.vue';
import cookie from './components/Cookie.vue';

export default {
    inject: ["mq"],

    components: {
        gotop,
        aco,
        btnsubmit,
        formdeleteinvalid,
        cookie,
    },

    data() {
        return {
            headerH: 0, //ヘッダーの高さ
            gnav: {
                isActive: false
            },
            value: "",
        }
    },

    computed: {
        showGnav() {
            if (this.mq.mdMinus && !this.gnav.isActive) {
                return false;
            } else {
                return true;
            }
        }
    },

    methods: {
        // wow.js初期化
        wowInit() {
            // SP/PC判定
            let offset;
            if (this.mq.mdMinus) {
                offset = 100; //SP
            } else {
                offset = 300; //PC
            }

            // 初期化
            const wow = new WOW({
                offset: offset,
                animateClass: 'animate__animated'
            });
            wow.init();
        },

        // ヘッダーの高さを取得
        getHeaderH() {
            this.headerH = this.$refs.header.getBoundingClientRect().height;
        },

        // URLにハッシュがあればページ読み込み時にスクロール
        loadScroll() {
            const hash = location.hash;
            if (hash && hash.match(/^#.+$/)) {
                window.onload = () => {
                    window.scrollTo(0, 0);
                    this.$scrollTo(hash, 500, {
                        offset: -this.headerH
                    });
                }
            }
        },

        // アンカーリンク
        anchorLink(hash) {
            console.log(hash);
            if (!document.getElementById(hash)) return;
            this.$scrollTo(`#${hash}`, 500, {
                offset: -this.headerH, //ヘッダー分ずらす
                onStart: this.gnav.isActive = false //グロナビを閉じる
            });
        },

        // グロナビ開閉アニメーション
        beforeEnter(el) {
			el.style.height = '0';
		},
		enter(el) {
            el.style.height = el.scrollHeight + 'px';
		},
		beforeLeave(el) {
            el.style.height = el.scrollHeight + 'px';
		},
		leave(el) {
            el.style.height = '0';
		},
    },

    mounted() {
        // wow.js
        this.wowInit();

        window.addEventListener('load', this.getHeaderH);
        window.addEventListener('resize', this.getHeaderH);
        this.loadScroll();

        new ScrollHint('.js-scrollable', {
            i18n: {
                scrollable: 'スクロールできます'
            }
        });
    },

}
</script>