<template>
    <button @click="handleSubmit" type="submit" class="btn is-submit" :disabled="isSubmitting"><slot name="txt"></slot></button>
</template>

<script>
export default {
  data() {
    return {
      isSubmitting: false // 送信中かどうかを表すフラグ
    }
  },
  methods: {
    handleSubmit(e) {
      this.isSubmitting = true // フラグをtrueにしてボタンを無効化
      var fm = e.target.closest("form");
      fm.submit();
    }
  }
}
</script>