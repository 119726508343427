<template>
<!-- cookie -->
<div class="cookie js-cookie">
	<div class="inner cookie__inner">
		<p class="cookie__txt">利便性向上、利用分析および広告配信等のためにクッキーを利用してアクセスデータを取得しています。<br class="md-none">詳しくは「<a href="/cookie/">クッキーの利用について</a>」をご覧ください。</p>
		<div class="cookie__close js-cookie-close">
			<a href="javascript:void(0);">閉じる</a>
		</div>
	</div>
</div><!-- ./cookie -->
</template>

<script>
export default {

    methods: {
        //クッキー利用のポップアップ
        funcCookiePopup() {
            const expire = 7; // 有効期限（日）
            let target = document.querySelector('.js-cookie');
            let close = document.querySelector('.js-cookie-close');
            const flag = localStorage.getItem('expireFlag');

            if (flag === null) {
                popup();
            } else {
                const data = JSON.parse(flag);
                const current = new Date();
                if (current.getTime() > data['expire']) {
                    popup();
                }     
            }
            
            close.addEventListener('click', function() {
                target.classList.remove('is-show');
                setWithExpiry('expireFlag', true, expire);
            });

            function setWithExpiry(key, value, expire) {
                const current = new Date();
                expire = current.getTime() + expire * 24 * 3600 * 1000;
                const item = {
                value: value,
                expire: expire
                };
                localStorage.setItem(key, JSON.stringify(item));
            }

            function popup() {
                target.classList.add('is-show');
            }
        }
    },

    mounted() {
        window.addEventListener('load', this.funcCookiePopup);
    },
}
</script>